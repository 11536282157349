@import 'bootstrap-variables';
// Override Boostrap variables
@import 'bootswatch/dist/zephyr/variables';
// Import Bootstrap source files from node_modules
@import 'bootstrap/scss/bootstrap';
@import 'bootswatch/dist/zephyr/bootswatch';

$body-color: $gray-900 !default;

body {
  margin: 0;
}

body.modal-open {
  overflow: hidden;
}

body,
#root,
#root > div,
.app-container {
  min-height: 100vh;
}

a {
  color: #533f03;
  font-weight: bold;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:after,
  &::before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.app-container {
  box-sizing: border-box;
  .view-container {
    width: 100%;
    height: calc(100% - 40px);
    min-height: calc(100vh - 74px); // 74px is the header
    /*overflow-y: auto;
    overflow-x: hidden;*/
    padding: 2rem 1rem 0 1rem;
    /*.card {
      padding: 1rem;
    }*/
    .view-routes {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
}

.fullscreen {
  position: fixed;
  top: 100px;
  left: 0px;
  width: 99% !important;
  height: calc(100vh - 110px) !important;
  margin: 5px;
  z-index: 1000;
  padding: 5px 25px 50px 25px !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Custom button styles
========================================================================== */

.icon-button > .btn {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5rem;
  line-height: 1rem;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Temporary workaround for availity-reactstrap-validation */
.invalid-feedback {
  display: inline;
}

/* other generic styles */

.title {
  font-size: 1.25em;
  margin: 1px 10px 1px 10px;
}

.description {
  font-size: 0.9em;
  margin: 1px 10px 1px 10px;
}

.shadow {
  box-shadow:
    rgba(0, 0, 0, 0.12) 0px 1px 6px,
    rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 2px;
}

.error {
  color: white;
  background-color: red;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.break-word {
  white-space: normal;
  word-break: keep-all;
}

.preserve-space {
  white-space: pre-wrap;
}

/* padding helpers */

@mixin pad($size, $side) {
  @if $size== '' {
    @if $side== '' {
      .pad {
        padding: 10px !important;
      }
    } @else {
      .pad {
        padding-#{$side}: 10px !important;
      }
    }
  } @else {
    @if $side== '' {
      .pad-#{$size} {
        padding: #{$size}px !important;
      }
    } @else {
      .pad-#{$side}-#{$size} {
        padding-#{$side}: #{$size}px !important;
      }
    }
  }
}

@include pad('', '');
@include pad('2', '');
@include pad('3', '');
@include pad('5', '');
@include pad('10', '');
@include pad('20', '');
@include pad('25', '');
@include pad('30', '');
@include pad('50', '');
@include pad('75', '');
@include pad('100', '');
@include pad('4', 'top');
@include pad('5', 'top');
@include pad('10', 'top');
@include pad('20', 'top');
@include pad('25', 'top');
@include pad('30', 'top');
@include pad('50', 'top');
@include pad('75', 'top');
@include pad('100', 'top');
@include pad('4', 'bottom');
@include pad('5', 'bottom');
@include pad('10', 'bottom');
@include pad('20', 'bottom');
@include pad('25', 'bottom');
@include pad('30', 'bottom');
@include pad('50', 'bottom');
@include pad('75', 'bottom');
@include pad('100', 'bottom');
@include pad('5', 'right');
@include pad('10', 'right');
@include pad('20', 'right');
@include pad('25', 'right');
@include pad('30', 'right');
@include pad('50', 'right');
@include pad('75', 'right');
@include pad('100', 'right');
@include pad('5', 'left');
@include pad('10', 'left');
@include pad('20', 'left');
@include pad('25', 'left');
@include pad('30', 'left');
@include pad('50', 'left');
@include pad('75', 'left');
@include pad('100', 'left');

@mixin no-padding($side) {
  @if $side== 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* end of padding helpers */

.no-margin {
  margin: 0px;
}
@mixin voffset($size) {
  @if $size== '' {
    .voffset {
      margin-top: 2px !important;
    }
  } @else {
    .voffset-#{$size} {
      margin-top: #{$size}px !important;
    }
  }
}

@include voffset('');
@include voffset('5');
@include voffset('10');
@include voffset('15');
@include voffset('30');
@include voffset('40');
@include voffset('60');
@include voffset('80');
@include voffset('100');
@include voffset('150');

.readonly {
  background-color: #eee;
  opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */

a:hover {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

button.anchor-btn {
  background: none;
  border: none;
  padding: 0;
  align-items: initial;
  text-align: initial;
  width: 100%;
}

a.anchor-btn:hover {
  text-decoration: none;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */

#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */

.width-min {
  width: 1% !important;
}

thead th {
  text-transform: none;
}

form .table {
  box-shadow: none;
  th {
    font-size: var(--bs-body-font-size);
    padding-bottom: 0;
  }
  td:first-child,
  th:first-child {
    padding-left: 0;
  }
}

.card .table {
  box-shadow: none;
}

.card-body .table-responsive {
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}
.card-body .alert {
  margin-left: -1rem;
  margin-right: -1rem;
}

table {
  a {
    font-weight: normal;
  }
  td {
    vertical-align: middle;
  }
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.card.comments,
.card-header.comments,
.row.comments {
  background-color: rgb(226, 226, 226);
}

.card.material-properties,
.card-header.material-properties,
.row.material-properties {
  background-color: rgb(224, 244, 255);
}

.card.biological-model,
.card-header.biological-model,
.row.biological-model {
  background-color: rgb(211, 255, 211);
}

.card.export-datasets,
.card-header.export-datasets,
.row.export-datasetsproperties {
  background-color: rgb(253, 231, 195);
}

.row.INFLAMMATION-effect-header,
.card-header.INFLAMMATION-effect-header {
  background-color: rgb(173, 214, 255);
}
.row.OXIDATIVE_STRESS-effect-header,
.card-header.OXIDATIVE_STRESS-effect-header {
  background-color: rgb(255, 253, 191);
}
.row.GENOTOXICITY-effect-header,
.card-header.GENOTOXICITY-effect-header {
  background-color: rgb(255, 204, 255);
}
.row.STRESS_RESPONSE-effect-header,
.card-header.STRESS_RESPONSE-effect-header {
  background-color: rgb(255, 191, 0);
}
.row.SYSTEMIC_MECHANISM-effect-header,
.card-header.SYSTEMIC_MECHANISM-effect-header {
  background-color: rgb(51, 153, 255);
  color: white;
}

.card.pots,
.card-header.pots,
.row.pots {
  background-color: #ff669a;
  color: white;
}

.card.aops,
.card-header.aops,
.row.aops {
  background-color: #d60393;
  color: white;
}

.card {
  .card-header,
  .card-body {
    padding: 0.5rem 1rem;
  }
}

.card + .card {
  margin-top: 24px;
}

.breadcrumb {
  border: none;
  box-shadow: none;
  padding-left: 0;
  .breadcrumb-item {
    padding-top: 0;
  }
}

.btn.disabled.pill {
  opacity: 100%;
  border-radius: 1.5rem;
}

.table {
  tr.details {
    background-color: rgba(0, 0, 0, 0.075);
  }
  tr.details.collapsed {
    display: none;
  }
  tr.header.expanded {
    border-bottom-style: hidden;
    background-color: rgba(0, 0, 0, 0.075);
  }

  .dataset-table-detail-container {
    padding-left: 0;
    .row:not(:last-child) {
      margin-bottom: 0.5rem !important;
    }
    .row > .col-auto:first-child {
      min-width: 12em;
    }
  }
}

.rbt-menu .dropdown-item {
  padding: 0.125rem 1rem;
}

.btn-close {
  font-size: 0.75rem;
  opacity: 0.75;
}

@media print {
  .app-container {
    padding-top: 0 !important;
  }
  #app-header {
    display: none !important;
  }
}

/*
.card {
  border: none;
  border-radius: unset;
  box-shadow: none;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  .card-body {
    padding: 1rem 0 0.5rem;
  }
  .card-header {
    border-radius: unset;
    border-bottom: none;
    padding: 0.5rem;
  }
}
*/

/* jhipster-needle-scss-add-main JHipster will add new css style */
