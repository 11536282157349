/* ==========================================================================
Main page styles
========================================================================== */
.hipster {
  display: inline-block;
  width: 100%;
  height: 497px;
  background: url('../../../content/images/logo_de.jpg') no-repeat center top;
  background-size: contain;
}
